import React from "react"
import FooterMenu from "./footerMenu"

import { Container } from "./footerStyles"

const Footer = () => (
  <Container>
    <FooterMenu />
  </Container>
)
export default Footer
