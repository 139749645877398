import React, { useState, useRef, useEffect } from "react"
import {
  OptionContainer,
  OptionWrapper,
  OptionInfo,
  Frequency,
  Info,
} from "./style"
import { Icon } from "utils/getIcon"
// import useOutsideAlerter from "utils/useOutsiderAlerter"

const Option = ({
  basic,
  plus,
  kickass,
  meetings,
  virtual,
  app,
  className,
  title,
  info,
  bubble,
  noLine,
  last,
  index,
  selectedOption,
  setSelectedOption,
}) => {
  const [showInfo, setShowInfo] = useState(false)
  // const optionRef = useRef(null)
  const id = `option-${index}`
  // useOutsideAlerter(optionRef, () => {
  //   if (showInfo) {
  //     setShowInfo(false)
  //     console.log("outsidealertier")
  //   }
  // })

  const handleClick = () => {
    setShowInfo(!showInfo)
    if (setSelectedOption) {
      setSelectedOption(id)
    }
  }

  useEffect(() => {
    if (selectedOption !== id) {
      setShowInfo(false)
    }
  }, [selectedOption])
  return (
    <OptionContainer
      basic={basic}
      className={className}
      meetings={meetings}
      last={last}
      // ref={optionRef}
    >
      <OptionWrapper
        noLine={noLine}
        showInfo={showInfo}
        kickass={kickass}
        basic={basic}
        app={app}
        last={last}
        virtual={virtual}
        bubble={bubble}
        onClick={() => handleClick()}
        meetings={meetings}
      >
        <span>{title}</span>
        <Icon name={bubble ? "info" : "arrow"} />

        {!bubble && <Icon className="check" name="check" />}

        {meetings && (
          <Frequency basic={basic}>
            {basic ? "----" : plus ? "Annual" : kickass ? "Quarterly" : null}
          </Frequency>
        )}

        {virtual && (basic || plus) && (
          <Frequency last={last} basic>
            {basic ? "----" : plus ? "----" : null}
          </Frequency>
        )}

        {app && basic && (
          <Frequency last={last} basic>
            ----
          </Frequency>
        )}
      </OptionWrapper>
      <OptionInfo bubble={bubble} showInfo={showInfo}>
        <Info>{info}</Info>
      </OptionInfo>
    </OptionContainer>
  )
}

export default Option
