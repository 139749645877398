import styled from "styled-components"
import { Link } from "gatsby"

import { Button } from "../Button"

import { device } from "../../utils/breakpoints.js"

export const TeaserContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${props => (props.row ? "row" : "column")};
  /* border: 1px dashed teal; */
  align-items: ${props => (props.centered ? "center" : "flex-start")};
  justify-items: flex-start;
  position: relative;
`

export const WrappingLink = styled(Link)`
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  padding-top: 60%;
  flex-basis: ${props => (props.row ? "50%" : null)};
  margin-bottom: ${props => (props.row ? "0" : "10px")};
  overflow: hidden;

  .gatsby-image-wrapper,
  > img {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    object-fit: cover;
    display: block;
    transition: 500ms;
    transform: scale(1);
/* 
    ${TeaserContainer}:hover & {
      transform: scale(1.1);
    } */
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props =>
    props.row ? "flex-start" : props.centered ? "center" : "flex-start"};
  justify-content: ${props => (props.centered ? "center" : "flex-start")};
  height: 100%;
  padding-right: 20px;
  padding-left: ${props => (props.row || props.centered ? "20px" : "0")};

  ${props =>
    props.overlapped &&
    `
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
  `};
`

export const Title = styled.h3`
  margin-bottom: 10px;
  transition: color 100ms;

  a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  transition: color 100ms;
  text-align: ${props => props.centered && 'center'};
  margin-bottom: 10px;
  transition: color 100ms;

  /* ${TeaserContainer}:hover & {
    color: ${props => props.theme.primaryColor};
  } */
`

export const CategoriesContainer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  font-weight: 600;
  grid-gap: 10px;
  margin-bottom: 10px;

  ${props =>
    props.catsTopRight &&
    `
		position: absolute;
		top: 0;
		right: 0;
	`};
`

export const Excerpt = styled.p``

export const Date = styled.span`
  font-weight: 600;
  margin-bottom: 10px;
`
export const NewButton = styled(Button)`
  position: static;
  :hover {
    background: none;
  }
  svg {
    vertical-align: middle;
    width: 30px;
    height: 32px;
    margin-right: 8px;

    g {
      fill: none;
    }
    path {
      fill: ${props => props.theme.blue};
    }
  }

  display: inline-block;
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.blue};
  letter-spacing: 0;
  line-height: 30px;
  background: transparent;
  margin-top: 10px;
  padding: 0%;

  @media ${device.desktop} {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: -0.3px;
    padding-left: 6px;

    svg {
      transform: scale(1.4);
    }
  }
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
