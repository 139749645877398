import styled from "styled-components"
import Img from "gatsby-image"
import { FlexCol } from "../FlexGrid/index"
import { device } from "../../utils/breakpoints"

export const LogoContainer = styled.div`
  margin: 0 auto;
  margin-top: 20px;
  width: 68px;

  @media ${device.desktop} {
    width: 70px;
  }
`
export const LogosContainer = styled.div`
  margin: 0 auto;
  margin-top: 20px;
  width: 245px;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
  }
  @media ${device.desktop} {
    display: block;
  }
`

export const Address = styled.div`
  margin: 20px auto;

  @media ${device.tablet} {
    margin: 0;
  }

  @media ${device.desktop} {
    margin-left: 20px;
  }
`
export const Info = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  letter-spacing: -0.22px;
  text-align: center;
  line-height: 20px;

  .name {
    font-weight: 600;
  }
  .email {
    font-weight: 600;
    color: #19cec9;
  }
  .phone {
    color: #000000;
  }

  @media ${device.desktop} {
    margin-top: 10px;
    text-align: left;
  }
`
export const MapContainer = styled.div`
  margin: 0 auto;
  margin-top: 34px;
  width: 96px;

  @media ${device.desktop} {
    margin: 0 auto;
    margin-top: 0;
    width: 110px;
  }
`
export const MapImg = styled(Img)`
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;

  @media ${device.desktop} {
    width: 110px;
  }
`
export const LogosImg = styled(Img)`
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;

  @media ${device.tablet} {
    height: 42px;
    width: 245px;
  }
`
export const LogoImg = styled(Img)`
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;

  @media ${device.desktop} {
    width: 68px;
    height: 42px;
  }
`
export const MapAddress = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media ${device.desktop} {
    flex-direction: row;
    align-items: middle;
    justify-content: flex-start;
    padding: 0 !important;
  }
`

export const LogosFlexCol = styled(FlexCol)`
  @media ${device.desktop} {
    order: 3;
  }
  @media ${device.desktop} {
    flex-direction: row;
    align-items: middle;
    padding: 0 !important;
  }
`
export const LogoFlexCol = styled(FlexCol)`
  margin-top: 20px;
  @media ${device.desktop} {
    order: 2;
  }
  @media ${device.desktop} {
    flex-direction: row;
    align-items: middle;
    padding: 0 !important;
    order: 2;
    margin-top: 0;
  }
`
export const Line = styled.div`
  width: 100%;
  border-top: 1px solid #e2e6ed;
  margin-bottom: 42px;
`
