import React, { useContext, useEffect, useRef, useState } from "react"
import { PopUpContext } from "../../context/PopUpContext"
import {
  PopUpContainer,
  PopUpBackground,
  ClosePopUpButton,
  PopUpMain,
  FormWrapper,
  SubmitBtn,
  OtherInfo,
  BookButton,
  ContactInfo,
} from "./style"
import { Form } from "../Form"
import { TextInput } from "../Form/TextInput"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Icon } from "utils/getIcon"
import useOutsideAlerter from "utils/useOutsiderAlerter"

const ContactPopUp = () => {
  const popUpRef = useRef(null)

  const data = useStaticQuery(
    graphql`
      query mapImage {
        file(relativePath: { eq: "map.png" }) {
          childImageSharp {
            fluid(maxWidth: 350, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const phoneIcon = <Icon name="book" />

  const { isPopUpOpen, setPopUpOpen } = useContext(PopUpContext)
  const [submitted, setSubmitted] = useState(false)

  useOutsideAlerter(popUpRef, () => {
    if (isPopUpOpen) {
      setPopUpOpen(false)
    }
  })

  // const closePopUp = () => {
  //   setPopUpOpen(false)
  // }

  const escHandler = event => {
    if (event.keyCode === 27 && isPopUpOpen) {
      setPopUpOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", escHandler)

    return function cleanup() {
      window.removeEventListener("keydown", escHandler)
    }
  })

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = values => {
    setSubmitted(true)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...values }),
    })
  }

  return (
    <PopUpContainer isPopUpOpen={isPopUpOpen}>
      <PopUpBackground />
      {/* <ClosePopUpButton label="close pop up" onClick={closePopUp} /> */}
      <PopUpMain ref={popUpRef}>
        <FormWrapper submitted={submitted}>
          <h3>{submitted ? "Thanks for your message" : "Get in touch"}</h3>
          <p>
            {submitted
              ? "We’ll get back in touch within 48 hours."
              : `Drop us a message and we'll get back in touch within 48 hours.`}
          </p>

          <Form
            onSubmit={handleSubmit}
            noValidate
            submitted={submitted}
            netlify
            name="contact"
          >
            <TextInput name="subject" type="hidden" value="Website Enquiry" />

            <TextInput
              name="name"
              placeholder="Your name"
              error="Please enter your name"
            />

            <TextInput name="phone" placeholder="Phone" pattern="\d*" error="Please enter your phone number" />

            <TextInput
              type="email"
              name="email"
              placeholder="Email address"
              error="Please enter an email"
            />
            <TextInput
              placeholder="Message"
              name="message"
              type="textarea"
              error="Please enter a message"
            />
            <SubmitBtn label="Send message" submit />
          </Form>
        </FormWrapper>
        <OtherInfo>
          <Img fluid={data.file.childImageSharp.fluid} />
          <ContactInfo>
            <h4>No Limit Advisory</h4>
            <p className="address">
              L35, 477 Collins St <br /> Melbourne VIC 3000 <br /> 0421 798 808
            </p>
            <a className="email" href="mailto:nick@nolimitadvisory.com.au">
              nick@nolimitadvisory.com.au
            </a>

            <BookButton label="Book a discovery call" icon={phoneIcon} link="https://calendly.com/nicklow"/>
          </ContactInfo>
        </OtherInfo>
      </PopUpMain>
    </PopUpContainer>
  )
}

export default ContactPopUp
