import styled from "styled-components"
import { device } from "breakpoints"

import { Teaser } from "../Teaser"
import {
  InfoContainer,
  Title,
  Excerpt,
  NewButton,
  ImageContainer,
  TeaserContainer,
} from "../Teaser/style"

export const NewTeaser = styled(Teaser)`
  margin-top: 10px;

  ${InfoContainer} {
    padding-right: 0;

    @media ${device.tablet} {
      margin-left: 30px;
      margin-top: 60px;
      max-width: 1350px;
      width: calc(58% - 40px);
    }
    @media ${device.desktop} {
      margin-left: 40px;
      margin-top: 85px;
      max-width: 1350px;
      width: calc(58% - 40px);
    }

    @media (min-width: 1530px) {
      margin-left: 80px;
    }
  }

  ${ImageContainer} {
    flex-basis: 50%;
    overflow: visible;
    padding-top: 100%;
    width: 100%;
    height: 100%;

    @media (min-width: 500px) {
      padding-top: 72%;
      width: 72%;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    @media ${device.tablet} {
      padding-top: 50%;
      width: 50%;
      margin-bottom: 0;
    }
    @media ${device.large} {
      width: 41.666%;
      height: 0;
      padding-top: 50%;
    }

    .gatsby-image-wrapper,
    > img {
      transform: none !important;
      ${TeaserContainer}:hover & {
        transform: none !important;
      }
    }
  }

  ${Title} {
    font-family: "Montserrat", "Arial", sans-serif;
    font-weight: 600 !important;
    font-size: 32px;
    color: #000000;
    letter-spacing: -0.5px;
    line-height: 36px;

    @media ${device.desktop} {
      margin-bottom: 20px;
      font-size: 42px;
      letter-spacing: -0.94px;
      line-height: 48px;
    }
    @media ${device.large} {
      margin-bottom: 30px;
      font-size: 48px;
      letter-spacing: -0.94px;
      line-height: 56px;
    }
    @media (min-width: 1530px) {
      font-size: 60px;
      line-height: 64px;
      width: 620px;
    }
  }

  ${Excerpt} {
    font-size: 18px;
    color: #585d6b;
    letter-spacing: -0.28px;
    line-height: 27px;
    margin-bottom: 10px;

    @media ${device.desktop} {
      margin-bottom: 30px;
    }
    @media ${device.large} {
      margin-bottom: 40px;
      font-size: 22px;
      letter-spacing: -0.34px;
      line-height: 30px;
    }
  }
  ${NewButton} {
    font-family: 500;
    font-size: 19px;
    color: #000000;
    letter-spacing: 0;

    @media ${device.large} {
      font-size: 22px;
      letter-spacing: 0;
    }
  }

  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    margin-left: 0;
  }

  @media (min-width: 1530px) {
    margin-left: -240px;
  }
`
