import React from "react"
import MainColumn from "../MainColumn/MainColumn"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {
  MainContainer,
  TestimonialContainer,
  Testimonials,
  Title,
  Text,
  Author,
} from "./style"

import { Icon } from "utils/getIcon"

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
  }

  return (
    <MainContainer id="testimonials-module">
      <MainColumn>
        <TestimonialContainer>
          <Icon name="quote-mark" />
          <Title>FROM OUR CLIENTS</Title>
          <Slider {...settings}>
            <Testimonials>
              <Text>
              We are EXTREMELY happy we chose Nick to walk with us on our business journey! Nick is a very down to earth person who is not only a pleasure to deal with, but professional and well versed in his field and he is very easy to communicate with.
              </Text>
              <Author href="https://www.monpoulet.com.au/about" target="_blank">Maxime & Lauren Croiset - MonPoulet</Author>
            </Testimonials>
            <Testimonials>
              <Text>
              From the moment we met with Nick - we knew he was a right fit for our business. A responsive & proactive accountant - and an expert at Xero
              </Text>
              <Author href="https://www.scaconnect.com/our-team" target="_blank">Jenny Murphy - SCA Connect</Author>
            </Testimonials>
            <Testimonials>
              <Text>
                We can’t speak of Nick highly enough. He’s been a pivotal
                partner for our business from day one, giving us solid advice
                and helping with strategies and forecasting to keep us focused
                and always moving in the right direction.
              </Text>
              <Author href="https://avenue.design" target="_blank">Flavio Argemi - Avenue</Author>
            </Testimonials>
          </Slider>
        </TestimonialContainer>
      </MainColumn>
    </MainContainer>
  )
}

export default Testimonial
