import styled from "styled-components"
import { device } from "utils/breakpoints.js"
import { Button } from "../Button/index.js"
import { MessageButton } from "components/ContactButtonsModule/style"

export const PopUpContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  opacity: ${props => (props.isPopUpOpen ? 1 : 0)};
  transition: 80ms;
  pointer-events: ${props => (props.isPopUpOpen ? "all" : "none")};
  overflow: scroll;
`

export const PopUpBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  opacity: 0.5;
`

export const ClosePopUpButton = styled(Button)`
  position: absolute;
  top: 30px;
  right: 30px;
  background: darkorange;

  :hover {
    background: teal;
  }
`

export const PopUpMain = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  margin: 20px auto;
  transform: translate3d(-50%, 0%, 0);
  width: ${props => `calc(100% - 2*${props.theme.mobileGutter})`};
  display: flex;
  flex-direction: column;
  background: #ffffff;
  max-width: 460px;
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.35);
  border-radius: 5px;

  @media ${device.desktop} {
    max-width: 732px;
    flex-direction: row;

    @media (min-height: 750px) {
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
`

export const FormWrapper = styled.div`
  padding: 30px;

  @media ${device.desktop} {
    padding: 55px;
    width: 60%;
    border-right: 1px solid #e2e6ed;
  }
  > h3 {
    font-size: 22px;
    margin-bottom: 10px;
    @media ${device.desktop} {
      font-size: 26px;
      margin-bottom: 22px;
    }
  }

  > p {
    font-size: 16px;
    margin-bottom: 20px;
    @media ${device.desktop} {
      font-size: 18px;
      margin-bottom: 30px;
    }
  }
`

export const SubmitBtn = styled(MessageButton)`
  margin-top: 10px;
  width: 100% !important;
`

export const OtherInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.desktop} {
    width: 40%;
  }
`

export const ContactInfo = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 18px;
    margin-bottom: 16px;
  }

  p.address {
    margin-bottom: 7px;
    color: #585d6b;
  }

  a.email {
    font-weight: 600;
    color: #19cec9;
    padding-bottom: 28px;
    opacity: 1;
    transition: 130ms;
    border-bottom: 1px solid #e2e6ed;

    :hover {
      opacity: 0.7;
    }
  }
`

export const BookButton = styled(Button)`
  background: none;
  padding: 0;
  margin-top: 15px;
  opacity: 1;
  transition: 140ms;
  justify-content: flex-start;
  :hover {
    background: teal;
  }

  span {
    color: black;
    font-family: "Montserrat", "Arial", sans-serif;
    font-size: 16px;
  }

  svg {
    margin-right: 13px;
    path {
      fill: #22afe5;
    }
  }

  :hover {
    background: none;
    opacity: 0.7;
  }
`
