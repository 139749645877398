import styled from "styled-components"
import { device } from "breakpoints"

export const Container = styled.div`
  width: 100%;

  padding-top: 50px;
  padding-bottom: 50px;

  @media ${device.desktop} {
    padding-top: 40px;
    padding-bottom: 50px;
  }
`
