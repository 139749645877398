import styled from "styled-components"
import { device } from "breakpoints"
import { FlexCol } from "components/FlexGrid"
import { MessageButton } from "components/ContactButtonsModule/style"

export const PricingModuleContainer = styled.div`
  padding: 50px 0;

  @media ${device.tablet} {
    padding: 70px 0;
  }

  @media ${device.large} {
    padding: 100px 0;
  }
`

export const TitleSection = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h2 {
    font-size: 24px;
    margin-bottom: 15px;

    @media ${device.desktop} {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }

  p {
    font-size: 18px;
    margin-bottom: 10px;

    @media ${device.desktop} {
      font-size: 16px;
    }
  }

  @media ${device.desktop} {
    padding-top: 26px;
    text-align: left;
    padding-left: 20px;
    padding-right: 50px;
    height: 175px;
  }

  @media ${device.large} {
    height: 155px;
  }
`
export const TitleColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const PriceTitleSection = styled.div`
  background: ${props => (props.color ? props.color : "#22afe5")};
  /* height: 150px; */
  color: white;
  text-align: center;
  padding: 0 20px;
  padding-top: 26px;
  padding-bottom: 16px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  h3 {
    font-size: 20px;
    color: inherit;
    margin-bottom: 10px;
  }

  h4 {
    font-size: 16px;
    color: inherit;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    font-weight: 600;
    /* max-width: 260px; */
    /* margin: 0 auto; */
  }

  @media ${device.desktop} {
    height: 175px;
    border-radius: 0;
  }

  @media ${device.large} {
    height: 155px;
  }
`

export const PricingColumn = styled.div`
  width: 100%;
  border-radius: inherit;
`

export const OptionWrapper = styled.div`
  cursor: pointer;
  background: #f0fafd;
  padding: 12px;
  padding-left: 20px !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid rgba(5, 50, 84, 0.2);

  &:hover span {
    text-decoration: underline;
  }

  span {
    font-size: 16px;
    font-weight: 600;
    color: #094e81;
    padding-right: 15px;
  }

  svg {
    /* transform: scale(-1); */
    width: ${props => (props.bubble ? "16px" : "13px")};
    flex-shrink: 0;
    align-self: center;
    transition: 120ms;
    height: auto;
    transform: ${props =>
      !props.bubble && props.showInfo ? "scale(-1)" : "scale(1)"};
    path {
      fill: #048db7;
    }
  }

  @media ${device.desktop} {
    svg.check {
      display: ${props => props.meetings && "none"} !important;
      display: ${props => props.virtual && !props.kickass && "none"} !important;
      display: ${props => props.app && props.basic && "none"} !important;
      top: ${props => (props.last ? "calc(50% - 13px)" : "50%")} !important;
    }
  }
`
export const OptionContainer = styled.div`
  position: relative;
  display: ${props => props.basic && "none"};

  @media ${device.desktop} {
    display: block;
    padding-bottom: ${props => props.last && "25px"};
  }

  :last-of-type {
    ${OptionWrapper} {
      border: none;

      @media ${device.desktop} {
        padding: 12px;
        border-bottom: 1px solid rgba(5, 50, 84, 0.2);
      }
    }
  }
`

export const OptionInfo = styled.div`
  height: auto;
  background-color: #fff;
  display: ${props => (props.showInfo ? "block" : "none")};
  position: ${props => props.bubble && "absolute"};
  top: ${props => props.bubble && "30%"};
  left: ${props => props.bubble && "97%"};
  width: 100%;
  z-index: 3;
  padding: 15px;
  padding-left: 20px;
  transition: 160ms;

  @media ${device.desktop} {
    display: block;
    width: 280px;
    border: 1px solid #1182ae;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.35);
    border-radius: 5px;
    opacity: ${props => (props.showInfo ? 1 : 0)};
    pointer-events: ${props => (props.showInfo ? "all" : "none")};
  }
`
export const TitleOptionList = styled.div`
  flex-direction: column;
  display: none;

  @media ${device.desktop} {
    display: flex;
  }
`

export const Info = styled.p`
  font-size: 14px;
  color: #585d6b;
  letter-spacing: -0.1px;
  line-height: 20px;
  display: block;
`
export const PricingOptionList = styled(TitleOptionList)`
  display: flex;
  border-radius: inherit;

  @media ${device.desktop} {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
  svg.check {
    display: none;
  }

  @media ${device.desktop} {
    background: ${props => (props.color ? props.color : "#159CD0")};
    ${OptionInfo} {
      display: none;
    }
    ${OptionWrapper} {
      pointer-events: none;
      background: none;
      span {
        padding-right: 28px;
        opacity: 0;
        pointer-events: none;
      }
      svg {
        display: none;
      }

      svg.check {
        display: block;
        width: 24px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);

        path {
          fill: white;
        }
        g {
          fill: white;
        }
      }
    }
  }
`

export const Frequency = styled.span`
  display: none;
  font-size: 16px;
  color: #ffffff !important;
  letter-spacing: -0.25px;
  text-align: center;
  position: absolute;
  top: ${props => (props.last ? "calc(50% - 14px)" : "50%")};
  left: 50%;
  opacity: 1 !important;
  padding: 0 !important;
  transform: translate3d(-50%, -50%, 0);
  letter-spacing: ${props => props.basic && "-1.7px"};
  @media ${device.desktop} {
    display: block !important;
  }
`

export const PriceCol = styled(FlexCol)`
  @media ${device.desktop} {
    :nth-of-type(2) {
      ${PriceTitleSection} {
        border-top-left-radius: 5px;
      }

      ${PricingOptionList} {
        border-bottom-left-radius: 5px;
      }
    }

    :nth-of-type(4) {
      ${PriceTitleSection} {
        border-radius: 0;
        border-top-right-radius: 5px;
      }

      ${PricingOptionList} {
        border-bottom-right-radius: 5px;
      }
    }
  }
`

export const DontFitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  width: 100%;

  @media ${device.desktop} {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 40px;
    > span {
      margin-bottom: 0;
      font-size: 18px;
      margin-right: 30px;
    }

    > a,
    button {
      margin-right: 0;
    }
  }
`
export const TextAndButton = styled.div`
  width: 100%;
  text-align: center;
  @media ${device.desktop} {
    width: auto;
  }
`
export const Note = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #585d6b;
  letter-spacing: -0.22px;
  line-height: 27px;
  position: relative;
  margin-bottom: 20px;

  span {
    font-weight: 600;
  }

  ::before {
    position: absolute;
    content: "";
    top: 11px;
    left: -10px;
    width: 5px;
    height: 5px;
    background-color: #f87ea1;
    border-radius: 50%;
  }
  @media ${device.desktop} {
    margin-bottom: 0;
    margin-left: 10px;
  }
`
export const NewMessageButton = styled(MessageButton)`
  margin-top: 10px;
  @media ${device.tablet} {
    margin-right: 0 !important;
    margin-left: 20px !important;
  }
`
