export const pricingData = [
  {
    title: "Business Basic",
    price: 249,
    excerpt:
      "Sorting out the basics. A plan that’s perfect for those starting up.",
    // tax: true,
    // asic: true,
    // xero: true,
    // app: true,
    // support: true,
    // meeting: true,
    // reports: "Annual",
    // budgeting: null,
  },
  {
    title: "Business Plus",
    price: 499,
    color: "#048DB7",
    color2: "#007EA5",
    excerpt: "For businesses serious about the next stage of their growth.",
    // tax: true,
    // asic: true,
    // xero: true,
    // app: true,
    // support: true,
    // meeting: true,
    // reports: "Quarterly",
    // budgeting: "Annual",
  },
  {
    title: "Supercharged",
    price: 999,
    color: "#0F72BC",
    color2: "#055C9E",
    excerpt: "Together, let’s take your business to the stratosphere.",
    // tax: true,
    // asic: true,
    // xero: true,
    // app: true,
    // support: true,
    // meeting: true,
    // reports: "Monthly",
    // budgeting: "Monthly",
  },
]
