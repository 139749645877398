import styled from "styled-components"
import { device } from "utils/breakpoints.js"

export const TextInputContainer = styled.div`
  display: flex;
  margin-bottom: ${props => (props.type === "hidden" ? "0" : "20px")};
  position: relative;
  flex-direction: column;

  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
  @media ${device.desktop} {
    margin-bottom: ${props => (props.type === "hidden" ? "0" : "23px")};
  }

  svg {
    position: absolute;
    top: ${props => (props.area ? "18px" : "50%")};
    left: 17px;
    width: 24px;
    transform: ${props => (props.area ? null : "translateY(-50%);")};

    @media ${device.desktop} {
      top: ${props => (props.area ? "26px" : "50%")};
      left: 20px;
    }
    path {
      transition: 100ms;
      fill: ${props => (props.fieldActive ? "#FF7F41 " : "#d3d2ce")};
    }
  }
`

export const Input = styled.input`
  display: block;
  outline: none;
  width: 100%;
  border:none;
  padding: ${props =>
    props.icon
      ? "23px 15px 3px 54px"
      : props.floatingLabel
      ? "23px 15px 3px 15px"
      : "5px 15px 5px 15px"};
  height: 64px;
  font-size: 18px;
  color: #585d6b;
  border-radius: 5px;
  border: 1px solid #e2e6ed;

  ::placeholder {
    opacity: 1;
  }
`

export const Area = styled.textarea`
  display: block;
  outline: none;
  width: 100%;
  border: none;
  padding: ${props =>
    props.icon
      ? "26px 15px 3px 54px"
      : props.floatingLabel
      ? "26px 15px 3px 15px"
      : "16px 15px 5px 15px"};
  height: 120px;
  color: #585d6b;
  resize: none;
  font-size: 18px;
  height: 140px;
  border-radius: 5px;
  border: 1px solid #e2e6ed;

  ::placeholder {
    opacity: 1;
  }
`
