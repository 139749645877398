import styled from "styled-components"
import { device } from "breakpoints"

export const MainContainer = styled.div`
  background-color: #f0fafd;
  max-width: 100%;
  padding-bottom: 30px;
  @media ${device.desktop} {
    padding-top: 60px !important;
  }
`
export const TestimonialContainer = styled.div`
  padding: 40px 0;
  position: relative;
  width: 20rem;
  max-width: 1120px;

  svg {
    transform: scale(1.2);

    overflow: visible;
    position: absolute;
    margin-left: calc(50% - 50px);

    @media ${device.desktop} {
      height: 86px !important;
    }
  }

  .slick-dots li button:before {
    font-size: 10px;
  }
  .slick-dots li.slick-active button:before {
    color: #19cec9 !important;
  }
  .slick-dots li {
    margin: 0 -3px;
  }
  .slick-dots:hover button:before {
    color: #19cec9 !important;
  }

  @media ${device.tablet} {
    width: 36rem;
  }
  @media ${device.desktop} {
    width: 45rem;
  }
`
export const Testimonials = styled.div`
  display: block;
  :focus {
    outline: 0;
  }
`
export const Title = styled.h3`
  font-weight: 600;
  font-size: 14px;
  color: #048db7;
  letter-spacing: 0.88px;
  text-align: center;
  line-height: 42px;
  z-index: 2;
  position: relative;
  margin-top: 18px;

  @media ${device.desktop} {
    font-size: 16px;
    letter-spacing: 1px;
  }
`

export const Text = styled.p`
  font-family: "Montserrat", "Arial", sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #595e6c;
  letter-spacing: -0.28px;
  text-align: center;
  line-height: 27px;
  margin-top: 32px;

  @media ${device.desktop} {
    font-size: 22px;
    letter-spacing: -0.34px;
    line-height: 36px;
    margin-top: 47px;
  }
`

export const Author = styled.a`
  display: block;
  font-weight: 600;
  font-size: 14px;
  color: #0f72bc;
  letter-spacing: -0.22px;
  text-align: center;
  line-height: 27px;
  margin-top: 45px;
  margin-bottom: 11px;

  &:hover {
    text-decoration: underline;
  }

  @media ${device.desktop} {
    font-size: 14px;
    letter-spacing: -0.22px;
    margin-top: 28px;
    margin-bottom: 43px;
  }
`
