import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { HomeMasthead } from "../components/HomeMasthead"
import { ContactPopUp } from "../components/ContactPopUp"
import { WhyModule } from "../components/WhyModule"
import { ServicesModule } from "../components/ServicesModule"
import { Testimonial } from "../components/Testimonial"
import { ProfileModule } from "../components/ProfileModule"
import { ContactButtonsModule } from "../components/ContactButtonsModule"
import { Footer } from "../components/Footer"

import PricingModule from "../components/PricingModule/PricingModule"
const IndexPage = () => {
  return (
    <Layout>
      <SEO title="No Limit Advisory - The next-gen accounting practice" />
      <ContactPopUp />
      <HomeMasthead />
      <WhyModule />
      <ProfileModule />
      <ServicesModule />
      <PricingModule />
      <Testimonial />
      <ContactButtonsModule />
      <Footer />
    </Layout>
  )
}

export default IndexPage
