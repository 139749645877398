import styled from "styled-components"
import { device } from "breakpoints"
import { BookButton } from "components/ProfileModule/style"

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  @media ${device.tablet} {
    flex-direction: row;
    width: 540px;
    margin: 0 auto;
    padding:0;

    > a, button {
      width: 260px;
    }
  }
`

export const ContactButtonsModuleContainer = styled.div`
  padding: 50px 0;

  @media ${device.tablet} {
    padding: 60px 0;
  }

  @media ${device.large} {
    padding: 80px 0;
  }
  h2 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 25px;

    @media ${device.desktop} {
      font-size: 26px;
    }
    @media ${device.large} {
      margin-bottom: 40px;
    }
  }
`

export const MessageButton = styled(BookButton)`
  background: #19cec9;
  margin-bottom: 20px;

  @media ${device.tablet} {
    margin: 0;
    margin-right: 20px;
  }

  :hover {
    background: #14b9b4;
  }
`
