import React, { useContext } from "react"
import {
  ButtonsContainer,
  ContactButtonsModuleContainer,
  MessageButton,
} from "./style"
import { BookButton } from "components/ProfileModule/style"
import { PopUpContext } from "context/PopUpContext"

const ContactButtonsModule = () => {
  const { setPopUpOpen } = useContext(PopUpContext)

  return (
    <ContactButtonsModuleContainer>
      <h2>Ready to get started?</h2>
      <ButtonsContainer>
        <MessageButton
          label="Send us a message"
          onClick={() => setPopUpOpen(true)}
        />
        <BookButton label="Book a discovery call" link="https://calendly.com/nicklow"/>
      </ButtonsContainer>
    </ContactButtonsModuleContainer>
  )
}

export default ContactButtonsModule
