import React from "react"
import { NewTeaser } from "./style"
import { MainColumn } from "../MainColumn"
import { useStaticQuery, graphql } from "gatsby"

const title = "The next-gen accounting practice."
const text =
  "Crunching the numbers isn't everyone's cup of tea. We give you the financial clarity you need to achieve your goals and have a kick-ass business."

const HomeMasthead = () => {
  const homeMastheadImage = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "hero-image.png" }) {
          childImageSharp {
            fluid(maxWidth: 670, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  // console.log(homeMastheadImage.image.childImageSharp)

  return (
    <MainColumn>
      <NewTeaser
        title={title}
        excerpt={text}
        fluid={homeMastheadImage.image.childImageSharp.fluid}
        loading="eager"
        fadeIn={false}
        date=""
        categories={[]}
        withButton
        btnLabel="Go beyond"
        icon="down-arrow"
        link="/"
        onClick={() =>
          document
            .getElementById("why-module")
            .scrollIntoView({ behavior: "smooth" })
        }
      />
    </MainColumn>
  )
}

export default HomeMasthead
