import React from "react"
import { MainColumn } from "../MainColumn"

import { FlexGrid, FlexRow, FlexCol } from "../FlexGrid/index"
import { useStaticQuery, graphql } from "gatsby"

import {
  LogoContainer,
  MapImg,
  MapContainer,
  Address,
  Info,
  LogosContainer,
  LogoImg,
  LogosImg,
  LogoFlexCol,
  LogosFlexCol,
  MapAddress,
  Line,
} from "./footerMenuStyles"

const FooterMenu = props => {
  const data = useStaticQuery(
    graphql`
      query FooterImages {
        allFile(
          filter: { absolutePath: { regex: "/footer-images/" } }
          sort: { fields: [name], order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(
                  maxWidth: 245
                  srcSetBreakpoints: [534, 768]
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )

  const imgs = data.allFile.edges

  return (
    <>
      <MainColumn>
        <Line />
      </MainColumn>

      <FlexGrid
        className={props.className}
        gap={[20, 20, 40, 190]}
        rowGap={[0, 50, 50]}
      >
        <FlexRow between="sm">
          <FlexCol xs={12} sm={6} md={4}>
            <MapAddress>
              <MapContainer>
                <MapImg fluid={imgs[0].node.childImageSharp.fluid} />
              </MapContainer>
              <Address>
                <Info>
                  <span className="name">No Limit Advisory</span>
                  <br /> L35, 477 Collins St, Melbourne VIC 3000
                  <br />{" "}
                  <a className="phone" href="tel:0421 798 808">
                    0421 798 808
                  </a>
                  <br />
                  <a
                    href="mailto:nick@nolimitadvisory.com.au "
                    className="email"
                  >
                    nick@nolimitadvisory.com.au
                  </a>
                </Info>
              </Address>
            </MapAddress>
          </FlexCol>

          <LogosFlexCol xs={12} sm={6} md={4}>
            <LogosContainer>
              <LogosImg fluid={imgs[2].node.childImageSharp.fluid} />
            </LogosContainer>
          </LogosFlexCol>
          <LogoFlexCol xs={12} md={4}>
            <LogoContainer>
              <a href="/">
                <LogoImg fluid={imgs[1].node.childImageSharp.fluid} />
              </a>
            </LogoContainer>
          </LogoFlexCol>
        </FlexRow>
      </FlexGrid>
    </>
  )
}
export default FooterMenu
