import styled from "styled-components"
import { device } from "breakpoints"
import { WhyTeaser } from "components/WhyModule/style"
import {
  ImageContainer,
} from "../Teaser/style"
export const ServicesModuleContainer = styled.div`
  background: #f0fafd;
  padding: 50px 0;

  @media ${device.tablet} {
    padding: 75px 0;
  }

  @media ${device.large} {
    padding: 100px 0;
  }
  h2 {
    font-size: 16px;
    text-align: center;
    color: #048db7;
    text-transform: uppercase;
    margin-bottom: 37px;
    letter-spacing: 1px;

    @media ${device.desktop} {
      margin-bottom: 40px;
    }
    @media ${device.large} {
      margin-bottom: 50px;
    }
  }
`

export const ServiceTeaser = styled(WhyTeaser)`
  text-align: center;
  width: 100%;

  ${ImageContainer} {
    height: auto;
    width: 80px;
    padding: 0;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media ${device.desktop} {
      /* width: 100px; */
      height: 120px;
    }
    .gatsby-image-wrapper {
      height: auto;
      position: relative !important;
    }
    @media ${device.desktop} {
      margin-bottom: 35px;
    }
  }
`
