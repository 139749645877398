import React, { useContext } from "react"
import {
  ProfileFlexGrid,
  ProfileFlexRow,
  ProfileFlexCol,
  ProfileImage,
  TextContainer,
  ImageContainer,
  LogoRowImage,
  BookButton,
} from "./style"
import { useStaticQuery, graphql } from "gatsby"

const ProfileModule = () => {
  const data = useStaticQuery(
    graphql`
      query profileImages {
        allFile(
          filter: { absolutePath: { regex: "/profile-module-images/" } }
          sort: { fields: [name], order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(srcSetBreakpoints: [534, 768], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )

  const imgs = data.allFile.edges

  return (
    <ProfileFlexGrid id="about-module" fullWidth gap={[30, 0]}>
      <ProfileFlexRow>
        <ProfileFlexCol xs={12} sm={6}>
          <ImageContainer>
            <ProfileImage fluid={imgs[0].node.childImageSharp.fluid} />
          </ImageContainer>
        </ProfileFlexCol>

        <ProfileFlexCol xs={12} sm={6}>
          <TextContainer>
            <h5>About</h5>
            <h3>Nick Low</h3>
            <p>
              Being an early adopter of Cloud Accounting, Nick is the ultimate
              Xero fanboy. Having over 10+ years experience in Tax and Business
              Advisory, Nick lives and breaths Small-Business. An avid coffee
              enthusiast, you’ll often find him amongst the bustling Melbourne
              laneways.
            </p>

            <h4>Qualifications</h4>
            <p>
              Registered Tax Agent, Chartered Accountant & Certified Xero
              Advisor.
            </p>

            <LogoRowImage
              fluid={imgs[1].node.childImageSharp.fluid}
              loading="eager"
            />

            <BookButton
              label="Book a discovery call"
              link="/"
              link="https://calendly.com/nicklow"
            />
          </TextContainer>
        </ProfileFlexCol>
      </ProfileFlexRow>
    </ProfileFlexGrid>
  )
}

export default ProfileModule
