import React from "react"
import { ReactComponent as Art } from "../images/icons/art.svg"
import { ReactComponent as Search } from "../images/icons/search.svg"
import { ReactComponent as IndustrialDesign } from "../images/icons/industrial_design.svg"
import { ReactComponent as DownArrow } from "../images/icons/down-arrow.svg"
import { ReactComponent as Arrow } from "../images/icons/arrow.svg"
import { ReactComponent as MailIcon } from "../images/icons/mail.svg"
import { ReactComponent as MessageIcon } from "../images/icons/message.svg"
import { ReactComponent as TitleIcon } from "../images/icons/title.svg"
import { ReactComponent as NameIcon } from "../images/icons/name.svg"
import { ReactComponent as PhoneIcon } from "../images/icons/phone.svg"
import { ReactComponent as QuoteMark } from "../images/icons/quote-mark.svg"
import { ReactComponent as BookIcon } from "../images/icons/book.svg"
import { ReactComponent as Info } from "../images/icons/info.svg"
import { ReactComponent as Check } from "../images/icons/check.svg"

const iconMap = {
  art: { icon: Art },
  "industrial-design": { icon: IndustrialDesign },
  search: { icon: Search },
  "down-arrow": { icon: DownArrow },
  "arrow": { icon: Arrow },
  mail: { icon: MailIcon },
  message: { icon: MessageIcon },
  title: { icon: TitleIcon },
  name: { icon: NameIcon },
  "phone": { icon: PhoneIcon },
  "book": { icon: BookIcon },
  'info': { icon: Info },
  'check': { icon: Check },
  "quote-mark": { icon: QuoteMark },
}

export const hasIcon = name => {
  return !!iconMap[name]
}

export const Icon = ({ name, className }) => {
  return iconMap[name] ? React.createElement(iconMap[name].icon, {className: className || name }) : false
}
