import React, { useState, useEffect } from "react"
import {
  PricingModuleContainer,
  TitleColumn,
  TitleSection,
  PricingColumn,
  PriceTitleSection,
  TitleOptionList,
  PricingOptionList,
  PriceCol,
  DontFitWrapper,
  Note,
  TextAndButton,
  NewMessageButton,
} from "./style"
import Option from "./Option"
import { pricingData } from "./pricingData"
import { FlexGrid, FlexRow, FlexCol } from "components/FlexGrid"

import { MainColumn } from "../MainColumn"
const allOptions = [
  {
    title: "Complete Accounting / Tax Compliance",
    info:
      "  We’ll keep the tax man at bay - with annual accounts, corporate & personal income tax filings, GST & Payroll submissions",
  },
  {
    title: "ASIC Compliancy",
    info:
      " Sick of late filing penalties and being charged to update an address? Never again!  our software automates this process ",
  },
  {
    title: "Xero Subscription (discounted)",
    info:
      "As Xero Partners - we pass on the discounted files to you at no margin",
  },

  {
    title: "Unlimited Support, Tax & Business Advice",
    info:
      "Phone/Email/Skype us - we love it. Gone are the days where clients are afraid to call their accountant in fear of getting a sneaky bill.",
  },
  {
    title: "Tax Planning Meeting",
    info: " We'll touch base before EOFY. Coffee anyone?",
  },
  {
    title: "App Training + Setup",
    info:
      "Having an open API, Xero talks well with other apps. We’ll make recommendations on any industry-specific software and support you through implementation.",
  },
  {
    title: "Management & budgeting meetings",
    info:
      "Whether your goal is to maximise profits, or just create a better work-life balance; We’ll develop KPI’s and keep you accountable!",
  },
  {
    title: "Virtual CFO",
    info: "You want your accountant to be more like a business partner",
  },
]

const PricingModule = () => {
  const [selectedOption, setSelectedOption] = useState("")

  useEffect(() => {
    console.log("selectedOption:", selectedOption)
  }, [selectedOption])

  return (
    <PricingModuleContainer id="pricing-module">
      <FlexGrid gap={[20, 20, 0]}>
        <FlexRow>
          <FlexCol xs={12} md={3}>
            <TitleColumn>
              <TitleSection>
                <h2>Transparent Pricing</h2>
                <p>
                  Check out our plans and what’s included. (All prices ex. GST)
                </p>
              </TitleSection>
              <TitleOptionList>
                {allOptions.map((option, index) => {
                  return (
                    <Option
                      last={false}
                      key={index}
                      index={index}
                      bubble
                      title={option.title}
                      info={option.info}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                    />
                  )
                })}
              </TitleOptionList>
            </TitleColumn>
          </FlexCol>

          {pricingData.map((price, index) => {
            return (
              <PriceCol key={index} xs={12} md={3}>
                <PricingColumn>
                  <PriceTitleSection color={price.color}>
                    <h3>{price.title}</h3>
                    <h4>{`from $${price.price} p/m`}</h4>
                    <p>{price.excerpt}</p>
                  </PriceTitleSection>

                  <PricingOptionList color={price.color2}>
                    {allOptions
                      .slice(0, allOptions.length - 3)
                      .map((option, index) => {
                        return (
                          <Option
                            key={index}
                            title={option.title}
                            info={option.info}
                          />
                        )
                      })}

                    <Option
                      basic={index === 0}
                      plus={index === 1}
                      kickass={index === 2}
                      app
                      title="App Training + Setup"
                      noLine
                      info={allOptions[5].info}
                    />

                    <Option
                      basic={index === 0}
                      plus={index === 1}
                      kickass={index === 2}
                      meetings
                      title={`${
                        index === 1 ? "Annual" : "Quarterly"
                      } Management & budgeting meetings`}
                      noLine
                      info={allOptions[6].info}
                    />

                    <Option
                      basic={index === 0}
                      plus={index === 1}
                      kickass={index === 2}
                      virtual
                      title="Virtual CFO"
                      last
                      noLine
                      info={allOptions[7].info}
                    />
                  </PricingOptionList>
                </PricingColumn>
              </PriceCol>
            )
          })}
        </FlexRow>
      </FlexGrid>
      <MainColumn>
        <DontFitWrapper>
          <TextAndButton>
            <span>Don't fit?</span>
            <NewMessageButton
              label="Free assessment"
              link="https://calendly.com/nicklow"
            />
          </TextAndButton>
        </DontFitWrapper>
      </MainColumn>
    </PricingModuleContainer>
  )
}

export default PricingModule
