import React from "react"
import { ServicesModuleContainer, ServiceTeaser } from "./style"
import { FlexGrid, FlexRow, FlexCol } from "components/FlexGrid"
import { useStaticQuery, graphql } from "gatsby"

const ServicesModule = () => {
  const data = useStaticQuery(
    graphql`
      query servicesImages {
        allFile(
          filter: { absolutePath: { regex: "/services-module-images/" } }
          sort: { fields: [name], order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(
                  maxWidth: 252
                  srcSetBreakpoints: [534, 768]
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )

  const imgs = data.allFile.edges

  return (
    <ServicesModuleContainer id="services-module">
      <h2>Our services</h2>
      <FlexGrid gap={[40]}>
        <FlexRow>
          <FlexCol xs={12} md={4}>
            <ServiceTeaser
              centered
              fluid={imgs[0].node.childImageSharp.fluid}
              title="Tax & Accounting"
              excerpt="Maximise your claims in the best-informed and most ethical manner. We'll keep the ATO off your back! We’ll take care of all your tax and compliance requirements ensuring you’re always up to date with your obligations."
            />
          </FlexCol>
          <FlexCol xs={12} md={4}>
            <ServiceTeaser
              centered
              fluid={imgs[1].node.childImageSharp.fluid}
              title="Xero Setup & Support"
              excerpt="Xero is an easy to use cloud-based application that will dramatically cut down time spent on your books. We can set up your xero file, convert from MYOB or Quickbooks and provide ongoing training and support."
            />
          </FlexCol>
          <FlexCol xs={12} md={4}>
            <ServiceTeaser
              centered
              fluid={imgs[2].node.childImageSharp.fluid}
              title="Formation & Startup"
              excerpt="Looking to kickstart that side-hustle? Or have you outgrown your current business structure? We'll step you through the right legal structure to run your business; to minimise tax and protect your assets"
            />
          </FlexCol>
        </FlexRow>
      </FlexGrid>
    </ServicesModuleContainer>
  )
}

export default ServicesModule
