import styled from "styled-components"
import { device } from "breakpoints"
import { Teaser } from "components/Teaser"
import {
  ImageContainer,
  CategoriesContainer,
  Date,
  Title,
  Excerpt,
  InfoContainer,
} from "../Teaser/style"
export const WhyModuleContainer = styled.div`
  padding: 50px 0;
  padding-bottom: 25px;

  @media ${device.tablet} {
    padding: 75px 0;
    padding-bottom: 37px;
  }

  @media ${device.desktop} {
    padding: 100px 0;
    padding-bottom: 50px;
  }
  h2 {
    font-size: 42px;
    text-align: center;
    color: #e2e6ed;
    margin-bottom: 37px;
    @media ${device.tablet} {
      margin-bottom: 55px;
      font-size: 60px;
    }

    @media ${device.desktop} {
      font-size: 90px;
    }

    @media ${device.large} {
      margin-bottom: 80px;
      font-size: 122px;
    }
  }
`

export const WhyTeaser = styled(Teaser)`
  text-align: center;
  width:100%;
  
  ${ImageContainer} {
    height: auto;
    width:64px;
    padding: 0;
    margin-bottom:20px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    @media ${device.desktop} {
      width: 84px;
      height: 96px;
    }
    .gatsby-image-wrapper {
      height:auto;
      position:relative !important;
    }
    @media ${device.desktop} {
    margin-bottom:35px;
    }
  }

  ${CategoriesContainer},${Date} {
    display:none;
  }

  ${InfoContainer} {
    justify-content:flex-start;
    padding:0;

  }

  ${Title} {
    font-size: 20px;
    margin-bottom:16px;

    @media ${device.desktop} {
      margin-bottom:24px;

    font-size: 26px;
    }
    }

  ${Excerpt} {
    font-size: 16px;
    max-width: 350px;
    @media ${device.desktop} {
      max-width:100%;
    font-size: 18px;
    }
  }
`
