import React from "react"
import PropTypes from "prop-types"
import { Icon } from "utils/getIcon"

import Categories from "./Categories"
import UseImgPlaceHolder from "../../hooks/useImgPlaceHolder"
import moment from "moment"
import AvenueImg from "components/AvenueImg"
import Img from "gatsby-image"
import {
  TeaserContainer,
  ImageContainer,
  InfoContainer,
  // WrappingLink,
  Title,
  Excerpt,
  Date,
  NewButton,
} from "./style.js"

/*
catsTopRight
row 
centered
*/

const Teaser = ({
  title,
  categories,
  excerpt,
  date,
  img,
  catsTopRight,
  row,
  className,
  centered,
  fluid,
  overlapped,
  withButton,
  btnLabel,
  onClick,
  link,
  icon,
  loading,
  fadeIn,
}) => {
  const btnIcon = <Icon name={icon} />

  return (
    <TeaserContainer className={className} row={row} centered={centered}>
      <ImageContainer row={row}>
        {fluid ? (
          <Img fluid={fluid} loading={loading} fadeIn={fadeIn} />
        ) : img ? (
          !img.srcSet ? (
            !img.sourceUrl ? (
              <UseImgPlaceHolder />
            ) : (
              <img src={img.sourceUrl} alt="" className="noSrcSet" />
            )
          ) : (
            <AvenueImg
              loading={loading}
              fadeIn={fadeIn}
              fluid={{
                aspectRatio: 1,
                src: img.sourceUrl,
                srcSet: img.srcSet,
                sizes: img.sizes || "800px",
              }}
            />
          )
        ) : (
          <UseImgPlaceHolder loading={loading} fadeIn={fadeIn} />
        )}
      </ImageContainer>

      <InfoContainer centered={centered} row={row} overlapped={overlapped}>
        {title && (
          <Title
            centered={centered}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        {date && <Date> {moment(date).format("DD MMM YYYY")}</Date>}

        {categories && (
          <Categories catsTopRight={catsTopRight} categories={categories} />
        )}
        {excerpt && (
          <Excerpt
            centered={centered}
            dangerouslySetInnerHTML={{ __html: excerpt }}
          />
        )}
        {withButton && (
          <NewButton
            label={btnLabel}
            link={link}
            icon={btnIcon}
            onClick={onClick}
          />
        )}
      </InfoContainer>
    </TeaserContainer>
  )
}

export default Teaser

Teaser.defaultProps = {
  title: "I'm a re-usable teaser",
  date: "1995-02-17T10:11:12",
  categories: ["art", "design", "food"],
  excerpt:
    "Here’s an introduction paragraph lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore east dolore magna.",
}

Teaser.propTypes = {
  title: PropTypes.string,
  categories: PropTypes.array,
  date: PropTypes.string,
  excerpt: PropTypes.string,
  img: PropTypes.object,
}
