import styled from "styled-components"
import { device } from "breakpoints"
import { FlexGrid, FlexCol, FlexRow } from "components/FlexGrid"
import Img from "gatsby-image"
import { Button } from "components/Button"

export const ProfileFlexGrid = styled(FlexGrid)`
  max-width: 1120px;
  overflow: hidden;
  padding-top: 25px;
  padding-bottom: 40px;
  @media ${device.tablet} {
    padding-top: 37px;
    padding-bottom: 70px;
  }
  @media ${device.desktop} {
    padding-top: 50px;
    padding-bottom: 90px;
  }

  @media ${device.large} {
    padding-bottom: 120px;
  }
`
export const ProfileFlexRow = styled(FlexRow)`
  overflow: visible;
`
export const ProfileFlexCol = styled(FlexCol)`
  overflow: visible;
`
export const ImageContainer = styled.div`
  padding-top: 100%;
  position: relative;
  width: 100%;
  height: 100%;

  @media ${device.tablet} {
    padding-top: 0;
  }
`
export const ProfileImage = styled(Img)`
  position: absolute !important;
  height: 100%;
  width: 105%;
  top: 0;
  left: -20px;
  img {
    object-position: left !important;
  }

  @media ${device.tablet} {
    width: 135%;
    height: auto;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 700px;
  }

  @media ${device.desktop} {
    left: 0px;
    width: 131%;
    max-width: 2000px;
  }

  @media ${device.large} {
    width: 141%;
    transform: none;
    top: 0;
  }
`

export const TextContainer = styled.div`
  padding: 0 20px;

  @media ${device.tablet} {
    padding-left: 60px;
    padding-right: 20px;
  }

  @media ${device.desktop} {
    padding-left: 80px;
    padding-right: 60px;
  }

  @media ${device.large} {
    padding-left: 100px;

    padding-right: 80px;
  }

  h4 {
    font-family: "Source Sans Pro", "Arial", "sans-serif";
    font-size: 16px;
    margin-bottom: 6px;

    @media ${device.tablet} {
      font-size: 18px;
    }
  }
  h5 {
    font-size: 12px;
    margin-bottom: 6px;
    @media ${device.tablet} {
      font-size: 16px;
    }
  }

  h3 {
    font-size: 42px;
    margin-bottom: 26px;

    @media ${device.tablet} {
      font-size: 52px;
      margin-bottom: 20px;
    }
  }

  p {
    margin-bottom: 28px;

    @media ${device.desktop} {
      font-size: 18px;
      margin-bottom: 33px;
    }
  }
`

export const BookButton = styled(Button)`
  background: #22afe5;
  border-radius: 24px;
  width: 100%;
  height: 48px;

  @media ${device.tablet} {
    width: auto;
    padding: 0 23px;
  }

  span {
    font-size: 16px;
    color: #ffffff;
    font-family: "Montserrat", "Arial", sans-serif;

    @media ${device.tablet} {
      font-size: 18px;
    }
  }

  :hover {
    background: #1c97c6;
  }
`

export const LogoRowImage = styled(Img)`
  height: 44px;
  margin-bottom: 30px;

  @media ${device.tablet} {
    margin-bottom: 40px;
    /* height: 52px; */
  }

  @media ${device.desktop} {
    height: 62px;
  }

  img {
    width: auto !important;
    object-fit: contain !important;

    @media ${device.tablet} {
    }
  }
`
