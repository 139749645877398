import React from "react"
import { WhyModuleContainer, WhyTeaser } from "./style"
import { FlexGrid, FlexRow, FlexCol } from "components/FlexGrid"
import { useStaticQuery, graphql } from "gatsby"

const WhyModule = () => {
  const data = useStaticQuery(
    graphql`
      query whyImages {
        allFile(
          filter: { absolutePath: { regex: "/why-module-images/" } }
          sort: { fields: [name], order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(srcSetBreakpoints: [534, 768], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )

  const imgs = data.allFile.edges

  // console.log(imgs)
  return (
    <WhyModuleContainer id="why-module">
      <h2>Why No Limit?</h2>
      <FlexGrid gap={[40]}>
        <FlexRow>
          <FlexCol xs={12} sm={4}>
            <WhyTeaser
              centered
              fluid={imgs[0].node.childImageSharp.fluid}
              title="Proactive approach"
              excerpt="An accountant that works with you, not just for you. There every step of the way. We don't mislead our clients with false information. Plain english - we tell it to you straight."
            />
          </FlexCol>
          <FlexCol xs={12} sm={4}>
            <WhyTeaser
              centered
              fluid={imgs[1].node.childImageSharp.fluid}
              title="Cloud-based tech"
              excerpt="A 100% Cloud-based , 100% paperless firm - we help move your accounting from the dark ages and into the future."
            />
          </FlexCol>
          <FlexCol xs={12} sm={4}>
            <WhyTeaser
              centered
              fluid={imgs[2].node.childImageSharp.fluid}
              title="Transparent pricing"
              excerpt="No timesheets, no hourly charges, no surprises! A complete service including great accounting software, unlimited advice and complete tax compliance."
            />
          </FlexCol>
        </FlexRow>
      </FlexGrid>
    </WhyModuleContainer>
  )
}

export default WhyModule
